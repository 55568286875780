<template>
  <div class="mx-auto">
    <div class="my-wrapper w-full mx-auto relative">
      <div v-if="loading" class="font-inter mt-10 text-center h-screen">
        <div v-if="loading">
          <parula-loading />
        </div>
      </div>
      <div v-else class="mt-12 lg:panel__margin">
        <section v-if="orderInfo">
          <Panel :orderInfo="orderInfo" :creatorData="creatorData" />
        </section>

        <section
          class="
            content__layout
            lg:grid lg:grid-cols-3 lg:grid-rows-2
            mt-24
            xl:px-14
          "
        >
          <div class="w-full h-full lg:row-span-1 lg:col-start-1 lg:col-end-3">
            <Creator :ds="creatorData" />
          </div>
          <div class="w-full h-full lg:row-span-1 lg:col-start-1 lg:col-end-3">
            <Artworks
              :itemlist="artworksItemlist"
              :pen_name="orderInfo.pen_name"
            />
          </div>
          <div
            class="
              w-full
              h-full
              lg:row-start-1 lg:row-end-3 lg:col-start-3 lg:col-end-4
            "
          >
            <div class="w-full h-full lg:px-6">
              <div class="bg-white">
                <nav class="flex flex-row border-b">
                  <button
                    class="
                      text-parula-dark
                      py-4
                      px-6
                      w-full
                      block
                      hover:text-gray-500
                      focus:outline-none
                    "
                    :class="{
                      'text-parula-dark border-b font-medium ':
                        mode === 'history',
                    }"
                    @click="mode = 'history'"
                  >
                    HISTORY
                  </button>
                  <button
                    class="
                      text-parula-dark
                      py-4
                      px-6
                      w-full
                      block
                      hover:text-gray-500
                      focus:outline-none
                    "
                    :class="{
                      'text-parula-dark border-b font-medium':
                        mode === 'policy',
                    }"
                    @click="mode = 'policy'"
                  >
                    POLICY
                  </button>
                </nav>
              </div>

              <div class="w-full block">
                <sub-history
                  v-if="mode === 'history'"
                  :historyInfo="historyInfo"
                />
                <sub-policy v-if="mode === 'policy'" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "./sub/Panel.vue";
import OpenapiService from "../../services/openapi.service";
import Creator from "./sub/Creator.vue";
import Artworks from "./sub/Artworks";
import SubHistory from "./sub/SubHistory.vue";
import SubPolicy from "./sub/SubPolicy.vue";
import ParulaLoading from "@/components/ParulaLoading.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    Panel,
    Creator,
    Artworks,
    SubHistory,
    SubPolicy,
    ParulaLoading,
  },

  data: function() {
    return {
      orderInfo: {},
      creatorData: {},
      artworksItemlist: {},
      historyInfo: [],
      mode: "history",
      loading: true,
    };
  },

  created() {
    const path = this.$route.path.split("/");
    const market_id = path[3];

    this.loadMarketDetail(market_id);
  },

  methods: {
    loadMarketDetail(market_id) {
      OpenapiService.orderGet(market_id)
        .then((res) => {
          console.log("res ==> ", res);
          this.orderInfo = res.data;
          const content_id = res.data.content_id;

          this.loadCreator(this.orderInfo.creator_id);
          this.loadArtworks(this.orderInfo.creator_id);
          this.loadHistory(content_id);
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },

    loadCreator: function(creator_id) {
      OpenapiService.userById(creator_id)
        .then((res) => {
          console.log("res ===> ", res);
          this.creatorData = res.data;
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },

    loadArtworks: function(creator_id) {
      OpenapiService.artworks(creator_id)
        .then((res) => {
          this.artworksItemlist = res.data.items;
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },

    loadHistory: function(content_id) {
      OpenapiService.productHistory(content_id)
        .then((res) => {
          this.historyInfo = res.data;
          this.loading = false;
        })
        .catch((e) => {
          notify({ type: "error", text: e.message });
        });
    },
  },
};
</script>

<style scoped>
#logo-other {
  display: inline-block;
}

.panel__margin {
  margin: 5vw 0px 0px 0px;
}

.content__layout {
  width: 100%;
  min-height: 50vh;
}

.border-b {
  border-bottom: 0.5px solid #262626;
}
</style>
