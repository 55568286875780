<template>
  <button id="heart" @click="clickHeart">
    <svg
      width="30"
      height="30"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 24.8047C14.7844 24.8053 14.0837 24.6003 13.4813 24.2141C11.7724 23.1155 8.48564 20.8611 5.70547 18.1082C2.29365 14.7272 0.563477 11.543 0.563477 8.64182C0.563477 6.6259 1.15957 4.77883 2.2875 3.3009C3.3571 1.89561 4.86801 0.890629 6.57753 0.447412C8.28705 0.00419517 10.0959 0.148468 11.7136 0.857053C13.1614 1.48186 14.4527 2.53527 15.5 3.93322C16.5473 2.53186 17.8386 1.47844 19.2864 0.857053C20.9041 0.148468 22.713 0.00419517 24.4225 0.447412C26.132 0.890629 27.6429 1.89561 28.7125 3.3009C29.8404 4.77883 30.4365 6.6259 30.4365 8.64182C30.4365 11.543 28.7063 14.7258 25.2945 18.1055C22.5144 20.8583 19.229 23.1128 17.5187 24.2113C16.9166 24.5986 16.2159 24.8045 15.5 24.8047ZM8.53828 1.90842C7.9976 1.90793 7.45924 1.97919 6.9373 2.12033C5.62729 2.47999 4.47116 3.2589 3.6458 4.33791C2.74756 5.51506 2.27246 7.00393 2.27246 8.64455C2.27246 11.0727 3.83174 13.8481 6.90791 16.8942C9.57393 19.5369 12.7506 21.7135 14.4049 22.7731C14.7315 22.9817 15.111 23.0926 15.4986 23.0926C15.8862 23.0926 16.2657 22.9817 16.5924 22.7731C18.2467 21.7108 21.4206 19.5342 24.0894 16.8942C27.1655 13.8481 28.7248 11.0727 28.7248 8.64455C28.7248 7.00393 28.2497 5.5178 27.3515 4.33791C26.5261 3.2589 25.37 2.47999 24.06 2.12033C21.7241 1.48596 18.4422 2.14904 16.2342 5.91291C16.1589 6.04154 16.0513 6.14822 15.922 6.22236C15.7927 6.2965 15.6463 6.3355 15.4973 6.3355C15.3482 6.3355 15.2018 6.2965 15.0725 6.22236C14.9432 6.14822 14.8356 6.04154 14.7604 5.91291C13.0254 2.95022 10.6219 1.90842 8.53828 1.90842Z"
        fill="#262626"
        v-if="clickedHeart == false"
      />
      <path
        d="M15.5 24.8047C14.7844 24.8053 14.0837 24.6003 13.4813 24.2141C11.7724 23.1155 8.48564 20.8611 5.70547 18.1082C2.29365 14.7272 0.563477 11.543 0.563477 8.64182C0.563477 6.6259 1.15957 4.77883 2.2875 3.3009C3.3571 1.89561 4.86801 0.890629 6.57753 0.447412C8.28705 0.00419517 10.0959 0.148468 11.7136 0.857053C13.1614 1.48186 14.4527 2.53527 15.5 3.93322C16.5473 2.53186 17.8386 1.47844 19.2864 0.857053C20.9041 0.148468 22.713 0.00419517 24.4225 0.447412C26.132 0.890629 27.6429 1.89561 28.7125 3.3009C29.8404 4.77883 30.4365 6.6259 30.4365 8.64182C30.4365 11.543 28.7063 14.7258 25.2945 18.1055C22.5144 20.8583 19.229 23.1128 17.5187 24.2113C16.9166 24.5986 16.2159 24.8045 15.5 24.8047ZM8.53828 1.90842C7.9976 1.90793 7.45924 1.97919 6.9373 2.12033C5.62729 2.47999 4.47116 3.2589 3.6458 4.33791C2.74756 5.51506 2.27246 7.00393 2.27246 8.64455C2.27246 11.0727 3.83174 13.8481 6.90791 16.8942C9.57393 19.5369 12.7506 21.7135 14.4049 22.7731C14.7315 22.9817 15.111 23.0926 15.4986 23.0926C15.8862 23.0926 16.2657 22.9817 16.5924 22.7731C18.2467 21.7108 21.4206 19.5342 24.0894 16.8942C27.1655 13.8481 28.7248 11.0727 28.7248 8.64455C28.7248 7.00393 28.2497 5.5178 27.3515 4.33791C26.5261 3.2589 25.37 2.47999 24.06 2.12033C21.7241 1.48596 18.4422 2.14904 16.2342 5.91291C16.1589 6.04154 16.0513 6.14822 15.922 6.22236C15.7927 6.2965 15.6463 6.3355 15.4973 6.3355C15.3482 6.3355 15.2018 6.2965 15.0725 6.22236C14.9432 6.14822 14.8356 6.04154 14.7604 5.91291C13.0254 2.95022 10.6219 1.90842 8.53828 1.90842Z"
        fill="red"
        v-else
      />
    </svg>
  </button>
</template>

<script>
import OpenapiAuth from "../../../services/openapiAuth.service";
export default {
  created: function () {
    this.getInfo();
  },

  data: function () {
    return {
      clickedHeart: false,
    };
  },

  props: {
    content_id: Number,
    customer_id: Number,
  },

  methods: {
    getInfo() {
      if (this.content_id == undefined || this.customer_id == undefined) {
        return;
      } else {
        OpenapiAuth.productDoeslike(2, this.content_id, this.customer_id).then(
          (res) => {
            if (res.data.length < 1) {
              // 데이터없으면 좋아요안누른거
              this.clickedHeart = false;
            } else {
              this.clickedHeart = true;
            }
          }
        );
      }
    },

    clickHeart: function () {
      this.clickedHeart = !this.clickedHeart;
      if (this.clickedHeart == true) {
        OpenapiAuth.productLike(2, this.content_id, this.customer_id).then(
          (res) => {
            // 2 = product 의미
          }
        );
      } else {
        console.log("this.content_id, this.customer_id ==> ", this.content_id, this.customer_id);
        OpenapiAuth.productDislike(2, this.content_id, this.customer_id).then(
          (res) => {}
        );
      }
    },
  },
};
</script>