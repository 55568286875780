<template>
  <div
    v-if="visible"
    class="fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    :class="[{ 'opacity-100': !visible }]"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        class="fixed inset-0 bg-black bg-opacity-70 transition-opacity"
        aria-hidden="true"
      ></div>

      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-sm
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle
          max-w-2xl
          md:max-w-4xl
          lg:max-w-5xl
          md:max-4xl
          sm:w-full
        "
      >
        <div v-if="process === 1">
          <div class="bg-white sm:px-4 pt-5 pb-8 sm:p-10 sm:pb-4">
            <div class="w-full flex flex-row-reverse">
              <div
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  rounded-full
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-7 h-7 sm:h-10 sm:w-10 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="hideModal"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div
              class="
                w-full
                flex flex-col
                items-center
                sm:items-left
                md:flex-row
                mt-5
              "
            >
              <section class="sm:w-1/2">
                <!-- ContentViewer 고도화 이후 교체 -->
                <!-- <img
                  :src="orderInfo.thumbnail"
                  class="w-screen sm:w-full h-80 object-cover"
                /> -->
                <div v-if="orderInfo.main_content_type === 'MP4'">
                  <video
                    :src="orderInfo.image"
                    class="w-full h-80 object-cover rounded-lg"
                    autoplay
                    loop
                    muted
                  ></video>
                </div>
                <div v-else>
                  <img
                    :src="orderInfo.image"
                    class="w-full h-80 object-cover rounded-lg"
                  />
                </div>

                <div class="flex justify-between mt-2">
                  <div>
                    <div class="text-lg">
                      {{ orderInfo.title }}
                    </div>
                    <div class="text-sm parula__text-gray">
                      @{{ orderInfo.pen_name }}
                    </div>
                  </div>
                  <div class="flex h-1/2 gap-x-2 text-sm parula__text-gray">
                    ROYALTY: {{ royalty }}%
                    <VueCustomTooltip
                      :label="royalty_label"
                      position="is-bottom"
                    >
                      <svg
                        width="13"
                        height="20"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76537 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.70841C9.58909 6.90672 9.875 5.96418 9.875 5C9.875 3.70707 9.36139 2.46709 8.44715 1.55285C7.53291 0.638615 6.29293 0.125 5 0.125ZM4.625 2.75C4.625 2.65054 4.66451 2.55516 4.73484 2.48484C4.80516 2.41451 4.90055 2.375 5 2.375C5.09946 2.375 5.19484 2.41451 5.26517 2.48484C5.33549 2.55516 5.375 2.65054 5.375 2.75V5C5.375 5.09946 5.33549 5.19484 5.26517 5.26517C5.19484 5.33549 5.09946 5.375 5 5.375C4.90055 5.375 4.80516 5.33549 4.73484 5.26517C4.66451 5.19484 4.625 5.09946 4.625 5V2.75ZM5 7.4375C4.88875 7.4375 4.78 7.40451 4.68749 7.3427C4.59499 7.28089 4.52289 7.19304 4.48032 7.09026C4.43775 6.98748 4.42661 6.87438 4.44831 6.76526C4.47002 6.65615 4.52359 6.55592 4.60225 6.47725C4.68092 6.39859 4.78115 6.34501 4.89026 6.32331C4.99938 6.3016 5.11248 6.31274 5.21526 6.35532C5.31804 6.39789 5.4059 6.46999 5.4677 6.56249C5.52951 6.65499 5.5625 6.76375 5.5625 6.875C5.5625 7.02418 5.50324 7.16726 5.39775 7.27275C5.29226 7.37824 5.14919 7.4375 5 7.4375Z"
                          fill="#DDDDDD"
                        />
                      </svg>
                    </VueCustomTooltip>
                  </div>
                </div>
              </section>

              <section class="sm:w-1/2 flex items-center justify-center px-10">
                <div class="w-full flex flex-col md:flex-row">
                  <div class="flex flex-col w-full">
                    <div class="mt-5 sm:mt-0 text-base parula__text-gray">
                      BUY NOW PRICE
                    </div>

                    <div class="w-full border-b parula__border-gray-soft p-2">
                      <div class="flex justify-between w-full">
                        <VueCustomTooltip
                          v-show="visual_parsed_price.length > digits"
                          :label="parsed_price + orderInfo.taSymbol"
                          position="is-bottom"
                        >
                          <span class="text-parula-dark text-2xl sm:text-4xl">
                            {{ visual_parsed_price }}
                          </span>
                        </VueCustomTooltip>
                        <!-- <span
                          class="
                            flex
                            items-end
                            text-sm
                            sm:text-lg
                            text-parula-dark
                          "
                        >
                          {{ orderInfo.taSymbol }}
                        </span> -->
                        <img
                          :src="orderInfo.asset_thumbnail"
                          alt="symbol"
                          class="ml-1 w-5 h-5 items-end self-end mb-1"
                        />
                      </div>
                    </div>

                    <div
                      v-if="orderInfo.maType === 'ERC1155'"
                      class="mt-5 w-full"
                    >
                      <div class="flex w-full gap-x-14">
                        <div class="w-1/2">
                          <div class="mt-5 sm:mt-0 text-base parula__text-gray">
                            REMAIN
                          </div>
                          <div
                            class="
                              flex
                              justify-end
                              items-center
                              border-b
                              parula__border-gray-soft
                              p-2
                            "
                          >
                            <span class="text-parula-dark text-2xl sm:text-4xl">
                              {{ remains }}
                            </span>
                          </div>
                        </div>
                        <div class="w-1/2">
                          <div class="mt-5 sm:mt-0 text-base parula__text-gray">
                            QUANTITY
                          </div>
                          <div
                            class="
                              flex
                              items-center
                              border-b
                              parula__border-gray-soft
                              p-2
                            "
                          >
                            <input
                              type="number"
                              class="
                                w-full
                                text-parula-dark text-2xl
                                sm:text-4xl
                                text-right
                              "
                              v-model="quantity"
                              @keydown="checkNatural"
                              step="1"
                              :max="remains"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="w-full flex md:justify-end">
              <div
                class="mt-3 sm:w-1/2 text-center sm:mt-0 sm:ml-4 sm:text-left"
              >
                <div class="mt-2">
                  <p class="text-sm text-gray-500 my-5">
                    You cannot withdraw your purchase. By purchasing, you
                    indicate that you have read and agree to the
                    <a href="/terms-of-service" target="_blank"
                      ><span class="underline"> Terms of purchase </span></a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 sm:flex sm:px-6 gap-x-2 justify-end my-5">
            <button type="button" class="button-radius-green" @click="hideModal">
              Cancel
            </button>
            <button type="button" class="button-green" @click="onBuy">
              Buy Now
            </button>
          </div>
        </div>
        <div
          v-if="process === 2"
          class="w-96 md:w-full h-96 flex items-center justify-center"
        >
          <div class="flex flex-col flex justify-center">
            <div
              class="text-gray-500 text-lgs font-light mt-2 text-center"
              @click="process = 3"
            >
              <!-- <ring-loader
                :loading="loading"
                :color="'#5bc0de'"
                :size="'70px'"
                class="mx-auto w-2/5"
              ></ring-loader> -->
              <img
                src="@/assets/img/cilo_loading.gif"
                class="mx-auto w-2/5 mb-8"
              />
              <p>Please wait...</p>
            </div>
          </div>
        </div>
        <div
          v-if="process === 4"
          class="w-96 md:w-full h-96 flex items-center justify-center"
        >
          <div class="flex flex-col flex justify-center">
            <div
              class="text-gray-800 text-2xl font-light mt-2 text-center mb-10"
            >
              An error has occurred.
            </div>
            <div class="text-red-400 text-xl font-light mt-2 text-center mb-10">
              {{ err_message }}
            </div>
            <button
              type="button"
              class="
                w-full
                inline-flex
                justify-center
                px-20
                shadow-sm
                px-4
                py-2
                text-base
                md:text-md
                font-normal
                parula__bg-black
                text-white
                sm:ml-3 sm:w-auto
                rounded-full
              "
              @click="
                hideModal();
                refresh();
              "
            >
              Close
            </button>
          </div>
        </div>
        <div
          v-if="process === 3"
          class="w-full flex items-center justify-center"
        >
          <div class="bg-white px-4 pt-5 pb-8 sm:p-14 sm:pb-4 w-full">
            <div class="w-full flex flex-row justify-center">
              <div
                class="text-sm text-center leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                <span class="text-lg">Completed Your Purchase</span>

                <div class="flex mt-5 parula__text-gray">
                  Purchase by&nbsp; <span>{{ customer.nickname }}</span
                  >&nbsp; for&nbsp;
                  <span>{{ parsed_price }}</span>
                  <img
                    :src="orderInfo.asset_thumbnail"
                    alt="symbol"
                    class="ml-1 w-4 h-4 self-end mb-1"
                  />
                </div>
              </div>
              <div class="absolute top-10 right-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="
                    hideModal();
                    refresh();
                  "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div class="mx-20">
              <div
                class="
                  flex flex-row
                  py-16
                  w-full
                  space-x-0
                  md:space-x-10
                  justify-between
                "
              >
                <div>
                  <div
                    v-if="orderInfo.sub_content_type === 'MP4'"
                    class="w-full h-full"
                  >
                    <video
                      :src="orderInfo.thumbnail"
                      class="w-40 h-40 md:w-60 md:h-60 shadow-md object-cover rounded-lg"
                      autoplay
                      loop
                      muted
                    ></video>
                  </div>
                  <div v-else class="w-full h-full">
                    <img
                      :src="orderInfo.thumbnail"
                      class="w-40 h-40 md:w-60 md:h-60 shadow-md object-cover rounded-lg"
                      alt="None"
                    />
                  </div>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 md:h-16 md:w-16 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="
                      hideModal();
                      refresh();
                    "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
                <div>
                  <img
                    v-if="!customer.avatar"
                    :src="pic"
                    class="
                      w-40
                      h-40
                      md:w-60 md:h-60
                      object-cover
                      shadow-md
                      rounded-full
                    "
                  />
                  <img
                    v-else
                    :src="customer.avatar"
                    class="
                      w-40
                      h-40
                      md:w-60 md:h-60
                      object-cover
                      shadow-md
                      rounded-full
                    "
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-center my-10">
              <button
                type="button"
                class="button-green"
                @click="
                  hideModal();
                  refresh();
                "
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import openapiAuth from "@/services/openapiAuth.service";
import { Parula } from "@/parula-js/src/parula.js";
import { notify } from "@kyvg/vue3-notification";
import pic from "@/assets/img/basic_image.png";
import BigNumber from "bignumber.js";

import {
  removeUnusedZero,
  setVisualPrice,
  handleZeroDB,
  handleZeroParula,
} from "@/util/price";

const PROCESS = {
  main: 1,
  pending: 2,
  complete: 3,
  error: 4,
};

export default {
  props: {
    orderInfo: Object,
    customer: Object,
    creatorData: {
      type: Object,
      required: true,
    },
  },

  data: function() {
    return {
      visible: false,
      process: 1,
      err_message: "",
      pic: pic,
      digits: 10,

      remains: 0,
      quantity: 1,
      parsed_price: "0",
      visual_parsed_price: "0",

      royalty: 0,
      royalty_label: "",
    };
  },

  created() {
    //qunatity
    let temp = this.quantity;

    if (temp < 0) {
      this.quantity = 0;
    } else if (temp > this.orderInfo.remain) {
      this.quantity = this.orderInfo.remain;
    } else this.quantity = temp;

    if (temp === "") {
      this.parsed_price = 0;
      return;
    }

    // this.parsed_price = new BigNumber(this.orderInfo.price_fixed);
    // this.parsed_price = parsed_price.multipliedBy(this.quantity);

    // orderInfo
    this.parsed_price = removeUnusedZero(
      this.orderInfo.price_fixed,
      this.orderInfo.decimals
    );

    this.visual_parsed_price = setVisualPrice(
      String(this.parsed_price),
      this.digits
    );

    this.remains = this.orderInfo.remain;
    this.royalty = Number((this.orderInfo.royalties / 100).toFixed(2));
    this.royalty_label = `
        The creator of this collection will receive ${this.royalty}% of the sale total from future sales of this item.
      `;
  },

  // watch: {
  //   quantity: function () {
  //     // temp -> quantity
  //   },

  //   orderInfo: function () {},
  // },

  methods: {
    checkNatural: function(event) {
      const key = event.key;

      if ((key >= 0 && key < 10) || key === "Backspace") {
        return true;
      } else event.preventDefault();
    },

    onBuy: async function() {
      if (Number(this.parsed_price) === 0) {
        notify({ type: "error", text: "Can't make an order" });
      } else {
        try {
          this.process = PROCESS.pending;
          this.orderInfo.order_signature;
          this.orderInfo.order_rawdata;

          const account = this.customer.wallet;

          // const accounts = await ethereum.request({
          //   method: "eth_requestAccounts",
          // });
          // const account = accounts[0];

          const parula = new Parula(window.ethereum, {}, (line) =>
            console.info("[*] ", line)
          );

          // this.parsed_price = new BigNumber(this.orderInfo.price_fixed);

          // let price_fixed = this.parsed_price.multipliedBy(this.quantity);
          // price_fixed = price_fixed.toString().replace(/\./g, "");

          // let price_fixed = handleZeroParula(
          //   this.orderInfo.price_fixed,
          //   this.decimals
          // );

          let price_fixed = this.orderInfo.price_fixed.replace(/\./g, "");

          price_fixed = new BigNumber(price_fixed)
            .multipliedBy(this.quantity)
            .toString();

          const params = {
            maker: account, //orderInfo 생성자 주소
            maTypeMV: this.orderInfo.taTypeMV, // takerAsset,  // ta, Asset(ETH, "0x", 200), ERC20 //구매
            maContractAddress: this.orderInfo.taContAddr, // contract
            maValue: price_fixed, //his.orderInfo.price_fixed, // 전체 판매 가격
            quantity: this.quantity, // 구매 갯수
            sellOrderId: this.orderInfo.id, // Order
          };

          console.log("sell-order params ::::", params);

          let total_price = new BigNumber(this.orderInfo.price_fixed)
            .multipliedBy(this.quantity)
            .toString();

          total_price = handleZeroDB(total_price, this.orderInfo.decimals);

          // console.log("params ::::", params);
          // console.log(
          //   "typeof total_price :",
          //   typeof total_price,
          //   "total_price :",
          //   total_price,
          //   "real total_price :",
          //   total_price * this.quantity
          // );

          const ret = await parula.createBuyOrderAndMatch(params);

          console.log("[*]ret ", ret);

          if (ret.status === false) {
            notify({ type: "error", text: "Cannot create a order" });
            this.process = PROCESS.error;
            this.err_message = ret.tx.err.message.split("\n")[0];
          } else {
            await openapiAuth
              .orderSold(
                this.orderInfo.id,
                this.customer.userId,
                this.orderInfo.price_fixed, // 개당 판매 금액 (DB 값 불러온 거 그대로 넣어야 한다.)
                total_price, // 전체 판매 금액
                0, // usd 안 쓴다
                0, // native 안 쓴다
                this.quantity,
                account, // 구매자 지갑
                ret.tx // transaction 정보
              )
              .then(() => {
                // 메일 발송이 에러가 나더라도 구매는 진행되어야 한다.
                this.postSoldMail();
              })
              .catch((error) => {
                notify({ type: "error", text: error.message });
                console.error(error);
                this.process = PROCESS.error;
              });
          }
        } catch (error) {
          notify({ type: "error", text: error.message });
          console.error(error);
          this.process = PROCESS.error;
        }
      }
    },

    async postSoldMail() {
      console.log("exec postSoldMail");

      const {
        id: order_id,
        creator_id,
        pen_name: creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        order_wallet,
        product_thumbnail,
        product_name,
        taSymbol,
        remain,
        total_supply,
      } = this.orderInfo;
      const {
        userId: buyer_id,
        nickname: buyer_nickname,
        mail: buyer_mail,
      } = this.customer;
      const msg = `sold null ${Date.parse(new Date())}`;
      const stage = `${process.env.VUE_APP_STAGE}`;

      const params = {
        order_id,
        creator_id,
        creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        buyer_id,
        buyer_nickname,
        buyer_mail,
        order_wallet,
        product_thumbnail,
        product_name,
        price_fixed: this.visual_parsed_price,
        taSymbol,
        remain,
        total_supply,
        quantity: this.quantity,
        auth_params: { msg },
        stage,
      };

      console.log("params ::::", params);

      // const authParams = { msg };

      // receiver: "seller" or "buyer"

      await openapiAuth
        .soldMail(params)
        .then((res) => {
          console.log("sold-mail res ::::", res);
          this.process = PROCESS.complete;
        })
        .catch((e) => {
          // 메일 발송이 에러가 나더라도 구매는 진행되어야 한다.
          this.process = PROCESS.complete;
          console.error(e.message);
        });
    },

    // 여기선 안 씀.
    refresh: function() {
      location.reload();
    },

    showModal() {
      this.visible = true;
    },

    hideModal() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

.button-green {
  font-weight: 600;
  @include button-radius(8rem, 2.5rem, #20A97F);
}

.button-radius-green {
  font-weight: 600;
  @include button-white-radius(8rem, 2.5rem, #20A97F, #20A97F);
}
</style>
