<template>
  <div v-if="itemlist.length > 0">
    <div class="my-5 sm:ml-3 text-center sm:text-left text-xl font-semibold">
      <span class="capitalize">{{ pen_name }}</span
      ><span>`s Artworks</span>
    </div>

    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="item in itemlist" :key="item.id" class="flex flex-col">
        <div class="w-48 h-56">
          <img
            class="w-full h-full object-cover rounded-sm shadow-sm"
            :src="item.image"
          />
        </div>
      </Slide>
      <template v-if="itemlist.length > 3" #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Breakpoints",

  props: {
    // creator_id: Number,
    pen_name: String,
    itemlist: Array,
  },

  components: {
    Carousel,
    Slide,
    Navigation,
  },

  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: "start",
      },
    },
    // itemlist: [],
    // ChoicedMarketId:""
    a: false,
  }),
});
</script>

<style scoped>
.carousel__item {
  min-height: 300px;
  width: 100%;
  background-color: var(--carousel-color-primary);
  color: var(--carousel-color-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
@import "@/scss/_variables.scss";

.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 50%;
}
.carousel__icon {
  fill: #262626;
}
</style>
