<template>
  <div v-html="htmlCounter"></div>
</template>

<script>
export default {
  props: {
    orderInfo: Object,
    end_at: String,
  },

  data: function () {
    return {
      interval: {},
      htmlCounter: "",
    };
  },

  mounted: function () {
    this.setupInterval();
  },

  methods: {
    remainTime(endtime) {
      const total = Date.parse(endtime) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },

    setupInterval() {
      const t = this.remainTime(this.end_at);
      // console.log(t.total, t.hour, t.days);

      if (t.total < 0 || this.orderInfo.order_state == 3) {
        this.htmlCounter =
          '<span class="text-base text-parula-dark">Auction Ended</span>';
        return;
      }

      this.interval = setInterval(() => {
        if (this.end_at) {
          const t = this.remainTime(this.end_at);
          //
          const html = `
            <span class="text-lg text-parula-dark">${t.days}</span>
            <span class="text-lg text-parula-dark mr-1">D</span>
            <span class="text-lg text-parula-dark">${("00" + t.hours).slice(
              -2
            )}</span>
            <span class="text-lg text-parula-dark mr-1">H</span>
            <span class="text-lg text-parula-dark">${("00" + t.minutes).slice(
              -2
            )}</span>
            <span class="text-lg text-parula-dark mr-1">M</span>
            <span class="text-lg text-parula-dark">${("00" + t.seconds).slice(
              -2
            )}</span>
            <span class="text-lg text-parula-dark">S</span>
          `;
          this.htmlCounter = html;
        } else {
          this.htmlCounter = "";
        }
      }, 1000);
    },
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/* .market-item-aution-time-val {
  font-size: 18pt;
  font-weight: 700;
}

.market-item-aution-time-dsp {
  font-size: 10pt;
  font-weight: 500;
  margin-right: 8px;
} */
</style>
