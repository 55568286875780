<template>
  <div
    v-if="visible"
    class="fixed z-40 inset-0 overflow-y-auto ease-out duration-300"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    :class="[{ 'opacity-100': !visible }]"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        class="fixed inset-0 parula__bg-gray bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="
          inline-block
          align-bottom
          bg-white
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle
          max-w-2xl
          md:max-w-4xl
          lg:max-w-5xl
          md:max-4xl
          sm:w-full
        "
      >
        <div class="w-full"></div>
        <div v-if="process == 1">
          <div class="bg-white sm:p-14 sm:pb-4">
            <div class="w-full flex flex-row justify-between">
              <h3
                class="
                  text-sm
                  underline
                  ml-2
                  mt-2
                  sm:ml-0 sm:mt-0
                  leading-6
                  font-medium
                  parula__text-gray
                "
                id="modal-title"
              >
                PLACE YOUR BID
              </h3>
              <div
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  rounded-full
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 sm:h-10 sm:w-10 mr-1 mt-2 sm:mr-0 sm:mt-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="hideModal"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full flex flex-col md:flex-row justify-center mt-5">
              <div class="w-full md:w-1/3">
                <!-- <img :src="orderInfo.thumbnail" class="w-full h-80 object-cover" /> -->

                <div v-if="orderInfo.main_content_type === 'MP4'">
                  <video
                    :src="orderInfo.image"
                    class="w-full h-80 object-cover rounded-lg"
                    autoplay
                    loop
                    muted
                  ></video>
                </div>
                <div v-else>
                  <img
                    :src="orderInfo.image"
                    class="w-full h-80 object-cover rounded-lg"
                  />
                </div>

                <div class="text-lg text-bold mt-2 ml-3 sm:0">
                  {{ orderInfo.title }}
                </div>
                <div class="text-sm parula__text-gray ml-3 sm:0">
                  {{ orderInfo.pen_name }}
                </div>
              </div>
              <div class="w-full md:w-2/3 sm:w-full mt-3 sm:px-3 sm:ml-3">
                <div class="w-full flex flex-col md:flex-row">
                  <div class="w-full py-3 border-b parula__border-black">
                    <div class="flex flex-col place-items-center">
                      <div class="text-sm parula__text-gray mb-3">
                        CURRENT HIGHEST BID
                      </div>
                      <div class="flex">
                        <VueCustomTooltip
                          v-show="visual_highest_bid.length > digits"
                          :label="highest_bid + orderInfo.taSymbol"
                          position="is-bottom"
                        >
                          <span
                            class="
                              font-inter
                              text-parula-dark text-2xl
                              sm:text-4xl
                            "
                          >
                            {{
                              visual_highest_bid === "undefined"
                                ? 0
                                : visual_highest_bid
                            }}
                          </span>
                        </VueCustomTooltip>
                        <!-- <span
                          class="
                            flex
                            items-end
                            text-sm
                            sm:text-lg
                            text-parula-dark
                            font-inter
                          "
                        >
                          {{ orderInfo.taSymbol }}
                        </span> -->

                        <img
                          :src="orderInfo.asset_thumbnail"
                          alt="symbol"
                          class="ml-1 w-5 h-5 items-end self-end mb-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-full py-3 border-b parula__border-black">
                    <div class="flex flex-col place-items-center">
                      <div class="text-sm parula__text-gray mb-3">
                        YOUR BALANCE
                      </div>
                      <div class="flex">
                        <VueCustomTooltip
                          v-show="visual_customer_balance.length > digits"
                          :label="customer_balance + orderInfo.taSymbol"
                          position="is-bottom"
                        >
                          <span
                            class="
                              font-inter
                              text-parula-dark text-2xl
                              sm:text-4xl
                            "
                          >
                            {{ visual_customer_balance }}
                          </span>
                        </VueCustomTooltip>
                        <!-- <span
                          class="
                            flex
                            items-end
                            text-sm
                            sm:text-lg
                            text-parula-dark
                            font-inter
                          "
                        >
                          {{ orderInfo.taSymbol }}
                        </span> -->

                        <img
                          :src="orderInfo.asset_thumbnail"
                          alt="symbol"
                          class="ml-1 w-5 h-5 items-end self-end mb-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full flex flex-col md:flex-row-reverse">
                  <div class="w-full sm:my-10 border-b parula__border-black">
                    <div class="flex w-full flex-col items-center">
                      <div class="text-sm parula__text-gray mb-3">
                        PLACE YOUR BID
                      </div>
                      <div
                        class="
                          relative
                          text-base
                          sm:test-lg
                          bg-transparent
                          parula__text-gray
                        "
                      >
                        <div class="flex items-center py-2">
                          <input
                            class="
                              sm:w-full
                              bg-transparent
                              text-right
                              border-none
                              px-2
                              leading-tight
                              focus:outline-none
                              text-base
                              sm:text-4xl
                            "
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            min="0"
                            placeholder="0"
                            v-model="bid_price"
                          />
                          <!-- <span class="mr-3 sm:mt-3 sm:mr-4 font-normal">{{
                            orderInfo.taSymbol
                          }}</span> -->
                          <img
                            :src="orderInfo.asset_thumbnail"
                            alt="symbol"
                            class="ml-1 w-5 h-5 items-end self-end mb-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex md:justify-end">
              <div
                class="mt-3 md:w-2/3 text-center sm:mt-0 sm:ml-4 sm:text-left"
              >
                <div class="mt-2">
                  <p class="text-sm parula__text-gray my-5">
                    Bids placed during an auction cannot be withdrawn. By
                    placing a bid you indicate that you have read and agree to
                    the
                    <a href="/terms-of-service" target="_blank"
                      ><span class="underline">Auction Terms of Use </span></a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 sm:flex sm:px-6 gap-x-2 justify-end my-5">
            <button type="button" class="button-radius-green" @click="hideModal">
              Cancel
            </button>
            <button type="button" class="button-green" @click="onBid">
              Submit Bid
            </button>
          </div>
        </div>
        <div
          v-if="process === 2"
          class="w-96 md:w-full h-96 flex items-center justify-center"
        >
          <div class="flex flex-col flex justify-center">
            <!-- <LoadingPage /> -->
            <div
              class="parula__text-gray text-lgs font-light mt-2 text-center"
              @click="process = 3"
            >
              <!-- <RingLoader
                :loading="loading"
                :color="'#5bc0de'"
                :size="'70px'"
                class="mx-auto w-2/5"
              ></RingLoader> -->
              <img
                src="@/assets/img/cilo_loading.gif"
                class="mx-auto w-2/5 mb-8"
              />
              <p>Please wait...</p>
            </div>
          </div>
        </div>
        <div
          v-if="process === 4"
          class="w-96 md:w-full h-96 flex items-center justify-center"
        >
          <div class="flex flex-col flex justify-center">
            <div
              class="
                parula__text-gray
                text-2xl
                font-light
                mt-2
                text-center
                mb-10
              "
            >
              An error has occurred.
            </div>
            <button
              type="button"
              class="
                w-full
                inline-flex
                justify-center
                px-20
                shadow-sm
                px-4
                py-2
                text-base
                md:text-md
                font-normal
                parula__bg-black
                text-white
                sm:ml-3 sm:w-auto
                rounded-full
              "
              @click="
                process = 1;
                hideModal();
                refresh();
              "
            >
              Close
            </button>
          </div>
        </div>
        <div
          v-if="process === 3"
          class="w-full flex items-center justify-center"
        >
          <div class="bg-white px-4 pt-5 pb-8 sm:p-14 sm:pb-4 w-full">
            <div class="w-full flex flex-row justify-center">
              <div
                class="
                  text-sm text-center
                  leading-6
                  font-medium
                  parula__text-gray
                "
                id="modal-title"
              >
                <span class="text-lg">Completed Your Bid</span>
                <div class="flex mt-5 parula__text-gray">
                  Bid by&nbsp; <span>{{ customer.nickname }}</span
                  >&nbsp; for&nbsp;
                  <span>{{ bid_price }}</span>
                  <img
                    :src="orderInfo.asset_thumbnail"
                    alt="symbol"
                    class="ml-1 w-4 h-4 self-end mb-1"
                  />
                </div>
              </div>
              <div class="absolute top-10 right-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-10 w-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="
                    hideModal();
                    refresh();
                  "
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div class="mx-20">
              <div
                class="
                  flex flex-row
                  py-16
                  w-full
                  space-x-0
                  md:space-x-10
                  justify-between
                "
              >
                <div>
                  <div
                    v-if="orderInfo.sub_content_type === 'MP4'"
                    class="w-full h-full"
                  >
                    <video
                      :src="orderInfo.thumbnail"
                      class="w-40 h-40 md:w-60 md:h-60 shadow-md object-cover rounded-lg"
                      autoplay
                      loop
                      muted
                    ></video>
                  </div>
                  <div v-else class="w-full h-full">
                    <img
                      :src="orderInfo.thumbnail"
                      class="w-40 h-40 md:w-60 md:h-60 shadow-md object-cover rounded-lg"
                      alt="None"
                    />
                  </div>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-10 w-10 md:h-16 md:w-16 parula__text-gray"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="
                      hideModal();
                      refresh();
                    "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
                <div>
                  <img
                    v-if="!customer.avatar"
                    :src="pic"
                    class="
                      w-40
                      h-40
                      md:w-60 md:h-60
                      object-cover
                      shadow-md
                      rounded-full
                    "
                  />
                  <img
                    v-else
                    :src="customer.avatar"
                    class="
                      w-40
                      h-40
                      md:w-60 md:h-60
                      object-cover
                      shadow-md
                      rounded-full
                    "
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-center my-10">
              <button
                type="button"
                class="button-green"
                @click="
                  hideModal();
                  refresh();
                "
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Parula } from "../../../parula-js/src/parula.js";
import openapiAuth from "../../../services/openapiAuth.service";
import { notify } from "@kyvg/vue3-notification";
import pic from "@/assets/img/basic_image.png";

// import InputDecimal from "@/components/InputDecimal.vue";

import {
  removeUnusedZero,
  handleZeroParula,
  handleZeroDB,
  bigNumberCompare,
  setVisualPrice,
} from "../../../util/price";

const PROCESS = {
  main: 1,
  pending: 2,
  complete: 3,
  error: 4,
};

export default {
  props: {
    orderInfo: Object,
    bid_list: Array,
    customer: Object,
    creatorData: {
      type: Object,
      required: true,
    },
  },

  data: function() {
    return {
      visible: false,
      process: 1,
      digits: 3,

      bid_price: "0",
      highest_bid: "0",
      customer_balance: "0",

      visual_highest_bid: "0",
      visual_customer_balance: "0",

      pic: pic,
    };
  },

  created() {
    if (!this.bid_list) {
      return;
    } else this.getCurrentBid();

    if (!this.orderInfo) {
      return;
    } else {
      this.bid_price = removeUnusedZero(
        this.orderInfo.price_begin,
        this.orderInfo.decimals
      );

      if (this.customer.wallet) {
        this.getBalance(this.customer.wallet);
      }
    }
  },

  methods: {
    getCurrentBid() {
      if (this.bid_list && this.bid_list.length > 0) {
        // 입찰 목록이 있으면 최고가로 셋팅
        const highest_bid_price = this.bid_list[0].price;

        this.highest_bid = removeUnusedZero(
          highest_bid_price,
          this.orderInfo.decimals
        );

        this.visual_highest_bid = setVisualPrice(
          String(this.highest_bid),
          this.digits
        );
      } else {
        // 입찰 목록이 없으면 시작가로 셋팅
        const begin_price = this.orderInfo.price_begin;

        this.highest_bid = removeUnusedZero(begin_price, this.digits);
        this.visual_highest_bid = setVisualPrice(
          String(this.highest_bid),
          this.digits
        );
      }
    },

    getBalance: async function(account) {
      try {
        const balanceABI = [
          {
            constant: true,
            inputs: [{ name: "_owner", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "balance", type: "uint256" }],
            type: "function",
          },
        ];

        console.log("this.orderInfo: ", this.orderInfo);
        console.log("this.orderInfo.taContAddr: ", this.orderInfo.taContAddr);

        //erc20
        if (this.orderInfo.taContAddr) {
          const parula = new Parula(window.ethereum, {}, (line) =>
            console.info("[*] ", line)
          );
          const contract = new parula.web3.eth.Contract(
            balanceABI,
            this.orderInfo.taContAddr
          );
          const weiBal = await contract.methods.balanceOf(account).call();

          console.log("weiBal: ", weiBal);

          const ethBal = parula.web3.utils.fromWei(weiBal, "ether");

          console.log("ethBal: ", ethBal);

          this.customer_balance = ethBal;
          this.visual_customer_balance = setVisualPrice(ethBal, this.digits);
        }
      } catch (e) {
        console.log(e.message);
      }
    },

    onBid: async function() {
      // console.log(
      //   bigNumberCompare(
      //     removeUnusedZero(this.bid_price),
      //     this.orderInfo.price_begin
      //   )
      // );

      if (this.bid_price.startsWith(".") || this.bid_price.endsWith(".")) {
        console.log("bid_price is starting with '.' ", this.bid_price);
        notify({
          type: "error",
          text: "[Invalid bid price] Please check your bid price",
        });
        return;
      }

      // 입찰가가 시작가보다 낮을 때 다음 단계로 넘어가지 않음
      if (
        bigNumberCompare(
          removeUnusedZero(this.bid_price, this.orderInfo.decimals), // 입찰가
          removeUnusedZero(this.orderInfo.price_begin, this.orderInfo.decimals) // 시작가
        ) === -1
      ) {
        notify({
          type: "error",
          text: "The bid price must be higher than the start price",
        });
        return;
      }

      if (Number(this.bid_price) > Number(this.customer_balance)) {
        notify({ type: "error", text: "Not enough balance to bid" });
      } else {
        try {
          this.process = PROCESS.pending;

          const account = this.customer.wallet;

          const parula = new Parula(window.ethereum, {}, (line) =>
            console.info("[*] ", line)
          );

          const bid_price_parula = handleZeroParula(
            this.bid_price,
            this.orderInfo.decimals
          );
          const bid_price_db = handleZeroDB(
            this.bid_price,
            this.orderInfo.decimals
          );

          const params = {
            maker: account, //구매자 지갑 주소
            maTypeMV: this.orderInfo.taTypeMV, // takerAsset,  // ta, Asset(ETH, "0x", 200), ERC20 //구매
            maContractAddress: this.orderInfo.taContAddr,
            maValue: bid_price_parula, //입찰가격
            auctionId: Number(this.orderInfo.id),
          };

          console.log(bid_price_parula);
          console.log(bid_price_db);

          const ret = await parula.createBidOrder(params);

          if (ret.status === false) {
            notify({ type: "error", text: "Cannot create a bid" });
            this.process = PROCESS.error;
          } else {
            openapiAuth
              .bidCreate(
                this.orderInfo.id,
                this.customer.userId,
                JSON.stringify(ret.data),
                ret.signature,
                this.customer.wallet,
                this.orderInfo.asset_id,
                bid_price_db
              )
              .then(() => {
                // 메일 발송이 에러가 나더라도 구매는 진행되어야 한다.
                this.postBidMail();
              })
              .catch((e) => {
                notify({ type: "error", text: e.message });
                this.process = PROCESS.error;
              });
          }
        } catch (e) {
          notify({ type: "error", text: e.message });
          this.process = PROCESS.error;
        }
      }
    },

    async postBidMail() {
      console.log("exec postBidMail");

      const {
        id: order_id,
        creator_id,
        pen_name: creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        order_wallet,
        product_thumbnail,
        product_name,
        taSymbol,
        remain,
        total_supply,
      } = this.orderInfo;
      const {
        userId: buyer_id,
        nickname: buyer_nickname,
        mail: buyer_mail,
      } = this.customer;
      const msg = `sold ${Date.parse(new Date())}`;
      const stage = `${process.env.VUE_APP_STAGE}`;

      const params = {
        order_id,
        creator_id,
        creator_pen_name,
        seller_id,
        seller_nickname,
        seller_mail,
        buyer_id,
        buyer_nickname,
        buyer_mail,
        order_wallet,
        product_thumbnail,
        product_name,
        bid_price: this.bid_price,
        taSymbol,
        remain,
        total_supply,
        quantity: this.quantity,
        auth_params: { msg },
        stage,
      };

      console.log("params ::::", params);

      // const authParams = { msg };

      // receiver: "seller" or "buyer"
      await openapiAuth
        .bidMail(params)
        .then((res) => {
          console.log("bid-mail res ::::", res);
          this.process = PROCESS.complete;
        })
        .catch((e) => {
          // 메일 발송이 에러가 나더라도 구매는 진행되어야 한다.
          this.process = PROCESS.complete;
          console.error(e.message);
        });
    },

    refresh: function() {
      location.reload();
    },

    showModal() {
      this.visible = true;
    },

    hideModal() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

.button-green {
  font-weight: 600;
  @include button-radius(8rem, 2.5rem, #20A97F);
}

.button-radius-green {
  font-weight: 600;
  @include button-white-radius(8rem, 2.5rem, #20A97F, #20A97F);
}

.hover__bg--black:hover {
  background-color: #262626;
  color: white;
}
</style>
