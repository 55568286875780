<template>
  <section class="grid lg:grid-cols-3">
    <article
      class="content__layout relative lg:col-span-2 parula__bg-gray-soft"
    >
      <content-viewer :filePath="orderInfo.image" />
      <div
        class="
          hidden
          lg:block
          absolute
          right-0
          bottom-0
          w-16
          h-16
          parula__bg-black
          bg-opacity-50
          cursor-pointer
        "
        @click="openPhoto"
      >
        <svg
          class="mx-auto h-full"
          width="50"
          height="50"
          viewBox="0 0 100 100"
          fill="white"
          xmlns="http:www.w3.org/2000/svg"
        >
          <g>
            <path
              d="m80 20h-20v4h13.17l-15.58 15.59 2.82 2.82 15.59-15.58v13.17h4z"
            />
            <path
              d="m24 26.83 15.59 15.58 2.82-2.82-15.58-15.59h13.17v-4h-20v20h4z"
            />
            <path
              d="m40 76h-13.17l15.58-15.59-2.82-2.82-15.59 15.58v-13.17h-4v20h20z"
            />
            <path
              d="m80 60h-4v13.17l-15.59-15.58-2.82 2.82 15.58 15.59h-13.17v4h20z"
            />
          </g>
        </svg>
      </div>
    </article>
    <article class="col-span-1 xl:px-6">
      <!-- 판매 정보 -->
      <!-- 좋아요 버튼 -->
      <div class="absolute -top-10 right-0 lg:static lg:px-4 flex justify-end">
        <LikeHeart
          v-if="customer_id && orderInfo.id"
          :content_id="orderInfo.content_id"
          :customer_id="customer_id"
        />
      </div>

      <div class="flex flex-col gap-y-5 mt-5 lg:mt-0">
        <!-- 작품 제목 -->
        <div
          v-if="orderInfo.title"
          class="font-inter font-semibold text-2xl px-8"
        >
          {{ orderInfo.title }}
        </div>

        <!-- 설명 -->
        <div class="flex flex-col gap-y-2 px-8">
          <div class="max-h-24 overflow-y-scroll hide-scrollbar">
            <span
              class="font-medium text-parula-dark"
              v-html="orderInfo.description"
            />
          </div>
          <div class="w-full block">
            <ul>
              <li
                class="cursor-pointer"
                style="margin-right: 0.5em; display: inline"
                v-bind:key="tag"
                v-for="tag in orderInfo.tag.split(',')"
                @click="onSearchByTag(tag)"
              >
                {{ tag }}
              </li>
            </ul>
          </div>
        </div>

        <!-- 판매 정보 START -->
        <div class="px-4">
          <hr class="block w-full mb-3 border-t-26" />
          <!-- Fixed Form START -->
          <div v-if="orderInfo.order_type === 1" class="px-4">
            <!-- START PRICE START -->
            <div class="flex flex-col gap-y-4">
              <div class="text-sm text-parula-gray-1">LIST PRICE</div>
              <div class="flex">
                <VueCustomTooltip
                  v-show="visual_price_fixed.length > digits"
                  :label="price_fixed + orderInfo.taSymbol"
                  position="is-bottom"
                >
                  <span class="text-parula-dark text-2xl lg:text-4xl">
                    {{ visual_price_fixed }}
                  </span>
                </VueCustomTooltip>
                <!-- <span class="text-sm lg:text-lg text-parula-dark">
                  {{ orderInfo.taSymbol }}
                </span> -->
                <img
                  :src="orderInfo.asset_thumbnail"
                  alt="symbol"
                  class="ml-1 w-5 h-5 self-end mb-1"
                />
              </div>
            </div>
            <!-- START PRICE END -->
          </div>
          <!-- Fixed Form END -->

          <!-- Auction Form START -->
          <div v-else-if="orderInfo.order_type === 2">
            <!-- Ends-In -->
            <div class="flex justify-between items-center px-4">
              <div class="text-sm text-parula-gray-1">ENDS IN</div>
              <div>
                <CountdownAuction
                  :end_at="orderInfo.end_at"
                  :orderInfo="orderInfo"
                />
              </div>
            </div>

            <hr class="block w-full mb-3 border-t-26 my-3" />

            <!-- Ends-In -->
            <div class="flex justify-between items-center px-4">
              <div class="text-sm text-parula-gray-1">STATE</div>
              <div>
                <ProgressAuction
                  :orderInfo="orderInfo"
                  :begin_at="orderInfo.begin_at"
                  :end_at="orderInfo.end_at"
                />
              </div>
            </div>

            <hr class="block w-full mb-3 border-t-26 my-3" />

            <!-- START-PRICE -->
            <div class="flex flex-col justify-between gap-y-4 px-4">
              <div class="flex items-center gap-x-1 text-sm text-parula-gray-1">
                START PRICE&nbsp;
                <VueCustomTooltip
                  label="The amount suggested by the auctioneer to open the bidding."
                  position="is-bottom"
                >
                  <svg
                    width="13"
                    height="20"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http:www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76537 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.70841C9.58909 6.90672 9.875 5.96418 9.875 5C9.875 3.70707 9.36139 2.46709 8.44715 1.55285C7.53291 0.638615 6.29293 0.125 5 0.125ZM4.625 2.75C4.625 2.65054 4.66451 2.55516 4.73484 2.48484C4.80516 2.41451 4.90055 2.375 5 2.375C5.09946 2.375 5.19484 2.41451 5.26517 2.48484C5.33549 2.55516 5.375 2.65054 5.375 2.75V5C5.375 5.09946 5.33549 5.19484 5.26517 5.26517C5.19484 5.33549 5.09946 5.375 5 5.375C4.90055 5.375 4.80516 5.33549 4.73484 5.26517C4.66451 5.19484 4.625 5.09946 4.625 5V2.75ZM5 7.4375C4.88875 7.4375 4.78 7.40451 4.68749 7.3427C4.59499 7.28089 4.52289 7.19304 4.48032 7.09026C4.43775 6.98748 4.42661 6.87438 4.44831 6.76526C4.47002 6.65615 4.52359 6.55592 4.60225 6.47725C4.68092 6.39859 4.78115 6.34501 4.89026 6.32331C4.99938 6.3016 5.11248 6.31274 5.21526 6.35532C5.31804 6.39789 5.4059 6.46999 5.4677 6.56249C5.52951 6.65499 5.5625 6.76375 5.5625 6.875C5.5625 7.02418 5.50324 7.16726 5.39775 7.27275C5.29226 7.37824 5.14919 7.4375 5 7.4375Z"
                      fill="#DDDDDD"
                    ></path>
                  </svg>
                </VueCustomTooltip>
              </div>
              <div class="flex">
                <VueCustomTooltip
                  v-show="visual_start_price.length > digits"
                  :label="start_price + orderInfo.taSymbol"
                  position="is-bottom"
                >
                  <span class="text-parula-dark text-2xl lg:text-4xl">
                    {{ visual_start_price }}
                  </span>
                </VueCustomTooltip>
                <!-- <span class="text-sm lg:text-lg text-parula-dark">
                  {{ orderInfo.taSymbol }}
                </span> -->
                <img
                  :src="orderInfo.asset_thumbnail"
                  alt="symbol"
                  class="ml-1 w-5 h-5 self-end mb-1"
                />
              </div>
            </div>

            <hr class="block w-full mb-3 border-t-26 my-3" />
            <!-- HIGHEST BID PRICE -->
            <div class="flex flex-col justify-between gap-y-4 px-4">
              <div class="text-sm text-parula-gray-1">
                HIGHEST BID PRICE&nbsp;
              </div>
              <div class="flex">
                <VueCustomTooltip
                  :label="highest_price + orderInfo.taSymbol"
                  position="is-bottom"
                  v-show="visual_highest_price.length > digits"
                >
                  <span class="text-parula-dark text-2xl lg:text-4xl">
                    {{ visual_highest_price }}
                  </span>
                </VueCustomTooltip>
                <!-- <span class="text-sm lg:text-lg text-parula-dark">
                  {{ orderInfo.taSymbol }}
                </span> -->
                <img
                  :src="orderInfo.asset_thumbnail"
                  alt="symbol"
                  class="ml-1 w-5 h-5 self-end mb-1"
                />
              </div>
            </div>

            <!-- BUY-NOW-PRICE -->
            <hr
              v-if="buy_now_price > 0"
              class="block w-full mb-3 border-t-26 my-3"
            />
            <div
              v-if="buy_now_price > 0"
              class="flex flex-col justify-between gap-y-4 px-4"
            >
              <div class="flex items-center gap-x-1 text-sm text-parula-gray-1">
                BUY NOW PRICE&nbsp;
                <VueCustomTooltip
                  label="The amount set by the auctioneer for immediate sale without a hammer price."
                  position="is-bottom"
                >
                  <svg
                    width="13"
                    height="20"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http:www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76537 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.70841C9.58909 6.90672 9.875 5.96418 9.875 5C9.875 3.70707 9.36139 2.46709 8.44715 1.55285C7.53291 0.638615 6.29293 0.125 5 0.125ZM4.625 2.75C4.625 2.65054 4.66451 2.55516 4.73484 2.48484C4.80516 2.41451 4.90055 2.375 5 2.375C5.09946 2.375 5.19484 2.41451 5.26517 2.48484C5.33549 2.55516 5.375 2.65054 5.375 2.75V5C5.375 5.09946 5.33549 5.19484 5.26517 5.26517C5.19484 5.33549 5.09946 5.375 5 5.375C4.90055 5.375 4.80516 5.33549 4.73484 5.26517C4.66451 5.19484 4.625 5.09946 4.625 5V2.75ZM5 7.4375C4.88875 7.4375 4.78 7.40451 4.68749 7.3427C4.59499 7.28089 4.52289 7.19304 4.48032 7.09026C4.43775 6.98748 4.42661 6.87438 4.44831 6.76526C4.47002 6.65615 4.52359 6.55592 4.60225 6.47725C4.68092 6.39859 4.78115 6.34501 4.89026 6.32331C4.99938 6.3016 5.11248 6.31274 5.21526 6.35532C5.31804 6.39789 5.4059 6.46999 5.4677 6.56249C5.52951 6.65499 5.5625 6.76375 5.5625 6.875C5.5625 7.02418 5.50324 7.16726 5.39775 7.27275C5.29226 7.37824 5.14919 7.4375 5 7.4375Z"
                      fill="#DDDDDD"
                    ></path>
                  </svg>
                </VueCustomTooltip>
              </div>
              <div class="flex">
                <VueCustomTooltip
                  :label="buy_now_price + orderInfo.taSymbol"
                  position="is-bottom"
                  v-show="visual_buy_now_price.length > digits"
                >
                  <span class="text-parula-dark text-2xl lg:text-4xl">
                    {{ visual_buy_now_price }}
                  </span>
                </VueCustomTooltip>
                <!-- <span class="text-sm lg:text-lg text-parula-dark">
                  {{ orderInfo.taSymbol }}
                </span> -->
                <img
                  :src="orderInfo.asset_thumbnail"
                  alt="symbol"
                  class="ml-1 w-5 h-5 self-end mb-1"
                />
              </div>
            </div>
          </div>
          <!-- Auction Form END -->

          <!-- 블록체인 정보 -->
          <hr class="block w-full mb-3 border-t-26 my-3" />
          <div class="px-4">
            <div class="text-sm text-parula-gray-1">BLOCKCHAIN INFO</div>
            <div class="flex flex-col gap-y-1 font-semibold mt-5">
              <div class="flex">
                <div class="w-28 text-parula-dark">Blockchain</div>
                <div class="text-parula-gray-1 font-medium">
                  <template v-if="orderInfo.chain_id == 1">
                    Ethereum
                  </template>
                  <template v-if="orderInfo.chain_id == 3">
                    Ethereum Ropsten
                  </template>
                  <template v-if="orderInfo.chain_id == 4">
                    Ethereum Rinkeby
                  </template>
                  <template v-if="orderInfo.chain_id == 1001">
                    Klaytn Baobab
                  </template>
                  <template v-if="orderInfo.chain_id == 8217">
                    Klaytn
                  </template>
                </div>
              </div>
              <div class="flex">
                <div class="w-28 text-parula-dark">Contract</div>
                <div class="text-parula-gray-1 parula__text-blue font-medium">
                  <template v-if="orderInfo.chain_id == 1">
                    <a
                      :href="
                        `https://etherscan.io/token/${orderInfo.maContAddr}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.maContAddr.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 3">
                    <a
                      :href="
                        `https://ropsten.etherscan.io/token/${orderInfo.maContAddr}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.maContAddr.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 4">
                    <a
                      :href="
                        `https://rinkeby.etherscan.io/token/${orderInfo.maContAddr}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.maContAddr.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 1001">
                    <a
                      :href="
                        `https://baobab.scope.klaytn.com/nft/${orderInfo.maContAddr}?tabId=nftTransfer`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.maContAddr.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 8217">
                    <a
                      :href="
                        `https://scope.klaytn.com/nft/${orderInfo.maContAddr}?tabId=nftTransfer`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.maContAddr.slice(0, 15) + "..." }}
                    </a>
                  </template>
                </div>
              </div>
              <div class="flex">
                <div class="w-28 text-parula-dark">Type</div>
                <div class="text-parula-gray-1 font-medium">
                  {{ orderInfo.maType }}
                </div>
              </div>
              <div class="flex">
                <div class="w-28 text-parula-dark">Token</div>
                <div class="text-parula-gray-1 font-medium">
                  {{ `${orderInfo.maSymbol}[${orderInfo.maTokenId}]` }}
                </div>
              </div>
              <div class="flex">
                <div class="w-28 text-parula-dark">Txn Hash</div>
                <div class="text-parula-gray-1 parula__text-blue font-medium">
                  <template v-if="orderInfo.chain_id == 1">
                    <a
                      :href="
                        `https://etherscan.io/tx/${orderInfo.transaction_hash}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.transaction_hash.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 3">
                    <a
                      :href="
                        `https://ropsten.etherscan.io/tx/${orderInfo.transaction_hash}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.transaction_hash.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 4">
                    <a
                      :href="
                        `https://rinkeby.etherscan.io/tx/${orderInfo.transaction_hash}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.transaction_hash.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 1001">
                    <a
                      :href="
                        `https://baobab.scope.klaytn.com/tx/${orderInfo.transaction_hash}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.transaction_hash.slice(0, 15) + "..." }}
                    </a>
                  </template>
                  <template v-if="orderInfo.chain_id == 8217">
                    <a
                      :href="
                        `https://scope.klaytn.com/tx/${orderInfo.transaction_hash}`
                      "
                      target="_blank"
                    >
                      {{ orderInfo.transaction_hash.slice(0, 15) + "..." }}
                    </a>
                  </template>
                </div>
              </div>

              <!-- ERC1155 -->
              <div
                v-if="orderInfo.maSymbol === `Paru1155`"
                class="flex flex-col gap-y-1"
              >
                <div class="flex">
                  <div class="w-28 text-parula-dark">Supply</div>
                  <div class="text-parula-gray-1 font-medium">
                    {{ orderInfo.total_supply }}
                  </div>
                </div>

                <div class="flex">
                  <div class="w-28 text-parula-dark">Remains</div>
                  <div class="text-parula-gray-1 font-medium">
                    {{ orderInfo.remain }}
                  </div>
                </div>

                <div class="relative flex">
                  <div class="w-28 text-parula-dark">Owners</div>
                  <div class="flex items-center text-parula-gray-1 font-medium">
                    {{ orderInfo.owners.length }}&nbsp;
                    <svg
                      @mouseover="isHovered = true"
                      @mouseout="isHovered = false"
                      class="cursor-pointer"
                      width="13"
                      height="20"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76537 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.70841C9.58909 6.90672 9.875 5.96418 9.875 5C9.875 3.70707 9.36139 2.46709 8.44715 1.55285C7.53291 0.638615 6.29293 0.125 5 0.125ZM4.625 2.75C4.625 2.65054 4.66451 2.55516 4.73484 2.48484C4.80516 2.41451 4.90055 2.375 5 2.375C5.09946 2.375 5.19484 2.41451 5.26517 2.48484C5.33549 2.55516 5.375 2.65054 5.375 2.75V5C5.375 5.09946 5.33549 5.19484 5.26517 5.26517C5.19484 5.33549 5.09946 5.375 5 5.375C4.90055 5.375 4.80516 5.33549 4.73484 5.26517C4.66451 5.19484 4.625 5.09946 4.625 5V2.75ZM5 7.4375C4.88875 7.4375 4.78 7.40451 4.68749 7.3427C4.59499 7.28089 4.52289 7.19304 4.48032 7.09026C4.43775 6.98748 4.42661 6.87438 4.44831 6.76526C4.47002 6.65615 4.52359 6.55592 4.60225 6.47725C4.68092 6.39859 4.78115 6.34501 4.89026 6.32331C4.99938 6.3016 5.11248 6.31274 5.21526 6.35532C5.31804 6.39789 5.4059 6.46999 5.4677 6.56249C5.52951 6.65499 5.5625 6.76375 5.5625 6.875C5.5625 7.02418 5.50324 7.16726 5.39775 7.27275C5.29226 7.37824 5.14919 7.4375 5 7.4375Z"
                        fill="#DDDDDD"
                      ></path>
                    </svg>
                  </div>
                  <!-- owners -->
                  <div
                    v-show="isHovered"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                    class="
                      absolute
                      top-6
                      left-10
                      lg:right-0
                      border
                      parula__border-black
                      z-10s
                    "
                  >
                    <div class="bg-white border-b p-2">
                      <h1>Owners</h1>
                    </div>
                    <div
                      v-for="(owner, index) in orderInfo.owners"
                      :key="index"
                      class="owners__bg w-full"
                    >
                      <div class="flex justify-between items-center w-full p-2">
                        <div class="flex items-center gap-x-2">
                          <!-- <img
                            v-if="!owner.avatar"
                            :src="pic"
                            alt="image"
                            class="w-10 h-10 rounded-full object-cover mr-4"
                          />
                          <img
                            v-else
                            :src="owner.avatar"
                            alt="image"
                            class="w-12 h-12 rounded-full object-cover mr-4"
                          /> -->
                          <div class="w-10 h-10">
                            <content-viewer
                              :filePath="owner.avatar"
                              shape="rounded-full"
                            />
                          </div>

                          <div class="flex flex-col">
                            <p class="w-32 text-sm text__ellipsis">
                              {{ owner.nickname }}
                            </p>
                            <p
                              class="w-32 parula__text-gray text-xs text__ellipsis"
                            >
                              {{ owner.account }}
                            </p>
                          </div>
                        </div>
                        <div class="items-center">
                          <div>
                            <span class="text-xs mr-1"> Quantity</span>
                            <span class="text-sm">{{ owner.balance }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 블록체인 정보 -->
        </div>
        <!-- 판매 정보 END -->

        <!-- Button Start -->
        <div class="mt-5 mx-4">
          <div v-if="is_sold || is_mine">
            <!--판매완료상태-->
            <button
              v-if="is_sold"
              class="
                w-full
                parula__bg-black
                text-white
                py-2
                px-4
                focus:outline-none focus:shadow-outline
                cursor-default
                rounded-full
              "
              type="button"
            >
              Sold Out
            </button>

            <!-- 내가 올린 오더 -->
            <button
              v-else-if="is_mine"
              class="
                w-full
                parula__bg-black
                text-white
                py-2
                px-4
                focus:outline-none focus:shadow-outline
                cursor-default
                rounded-full
              "
              type="button"
            >
              My Order
            </button>
          </div>
          <!-- 일반 판매 -->
          <div v-else-if="orderInfo.order_type === 1">
            <button
              @click="onBuyModal"
              class="
                w-full
                parula__bg-green
                text-white
                py-2
                px-4
                focus:outline-none focus:shadow-outline
                rounded-full
              "
              type="button"
            >
              Buy Now
            </button>
          </div>
          <!-- 옥션 판매 -->
          <div v-else-if="orderInfo.order_type === 2">
            <div v-if="is_auction_end">
              <button
                class="
                  w-full
                  parula__bg-black
                  text-white
                  py-2
                  px-4
                  focus:outline-none focus:shadow-outline
                  cursor-default
                  rounded-full
                "
                type="button"
              >
                Auction is Ended
              </button>
            </div>

            <div class="flex flex-col gap-2" v-else>
              <button
                v-if="orderInfo.immediate_buyable !== null"
                @click="onBuyModal"
                class="
                  w-full
                  parula__bg-green
                  text-white
                  py-2
                  px-4
                  focus:outline-none focus:shadow-outline
                  rounded-full
                "
                type="button"
              >
                Buy Now
              </button>
              <button
                class="
                  w-full
                  bg-white
                  text-black
                  border
                  parula__border-black
                  hover__bg--black
                  py-2
                  px-4
                  focus:outline-none focus:shadow-outline
                  rounded-full
                "
                type="button"
                @click="onBidModal"
              >
                Place a Bid
              </button>
            </div>
          </div>
        </div>
        <!-- Button End -->
      </div>
    </article>
  </section>

  <!-- Notify, Modal -->
  <section>
    <notifications position="bottom right" />
    <div v-if="createdEnd">
      <ModalPlaceBid
        ref="ModalPlaceBid"
        :orderInfo="orderInfo"
        :customer="customer"
        :bid_list="bid_list"
        :creatorData="creatorData"
      />
      <ModalBuyNow
        ref="ModalBuyNow"
        :orderInfo="orderInfo"
        :customer="customer"
        :creatorData="creatorData"
      />
    </div>

    <div
      class="
        fixed
        left-0
        top-0
        z-50
        w-screen
        h-screen
        parula__bg-black
        bg-opacity-90
        flex
      "
      v-if="open_viewer"
    >
      <!-- <ContentViwerDetailModal
          :image="orderInfo.image"
          :fileType="orderInfo.main_content_type"
        /> -->

      <content-detail-viewer
        :filePath="orderInfo.image"
        :fileType="orderInfo.main_content_type"
      />
      <button
        class="absolute right-2 top-0 w-12 h-12 cursor-pointer"
        @click="closePhoto"
      >
        <svg
          class="mx-auto"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8323 10.0001L19.6199 2.21215C20.1267 1.70557 20.1267 0.88651 19.6199 0.379933C19.1133 -0.126644 18.2943 -0.126644 17.7877 0.379933L9.99988 8.16793L2.21228 0.379933C1.70548 -0.126644 0.886669 -0.126644 0.380103 0.379933C-0.126701 0.88651 -0.126701 1.70557 0.380103 2.21215L8.1677 10.0001L0.380103 17.7881C-0.126701 18.2947 -0.126701 19.1138 0.380103 19.6204C0.632555 19.8731 0.964493 20 1.29619 20C1.62789 20 1.95959 19.8731 2.21228 19.6204L9.99988 11.8324L17.7877 19.6204C18.0404 19.8731 18.3721 20 18.7038 20C19.0355 20 19.3672 19.8731 19.6199 19.6204C20.1267 19.1138 20.1267 18.2947 19.6199 17.7881L11.8323 10.0001Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import OpenapiAuth from "@/services/openapiAuth.service";
import OpenapiService from "@/services/openapi.service";
import SessionService from "../../../services/session.service.js";
import pic from "@/assets/img/basic_image.png";
import LikeHeart from "./LikeHeart";
import ModalBuyNow from "./ModalBuyNow.vue";
import { notify } from "@kyvg/vue3-notification";

import ContentViewer from "../../../components/ContentViewer.vue";
import ContentDetailViewer from "../../../components/ContentDetailViewer.vue";

// Auction
import CountdownAuction from "../../../components/CountdownAuction.vue";
import ProgressAuction from "./ProgressAuction.vue";
import ModalPlaceBid from "./ModalPlaceBid.vue";

// util function
import { removeUnusedZero, setVisualPrice } from "../../../util/price";
import { checkMetamaskInstallation } from "../../../util/wallet";

const NETWORK = {
  1: "mainnet",
  3: "ropsten",
  4: "rinkeby",
};

export default {
  props: {
    orderInfo: {
      type: Object,
      required: true,
    },
    creatorData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isHovered: false,
      is_mine: false,
      is_sold: false,
      is_auction_end: false,
      open_viewer: false,
      forSale: false,
      pic: pic,

      // *** normal ***
      price_fixed: "0",
      visual_price_fixed: "0",
      // *** normal ***

      // *** auction ***
      start_price: "0",
      highest_price: "0",
      buy_now_price: "0",

      visual_start_price: "0",
      visual_highest_price: "0",
      visual_buy_now_price: "-",
      visual_price: "0",
      // *** auction ***
      digits: 3,

      bid_list: [],
      customer_id: "",
      exchangeRate: [],
      customer: {},
      customer_account: "",
      mainphoto: false,
      createdEnd: false,
    };
  },

  components: {
    LikeHeart,
    CountdownAuction,
    ProgressAuction,
    ModalPlaceBid,
    ModalBuyNow,
    ContentViewer,
    ContentDetailViewer,
  },

  async created() {
    const path = this.$route.path.split("/");
    const market_id = path[3];

    // 비로그인 유저도 가능
    this.bid_list = await this.getBidUsers(market_id);
    this.forSale = await this.checkForSale();
    // this.loadExRate();

    // 로그인 정보
    const session = SessionService.loadSession();

    this.customer = session;
    this.customer_id = session.userId;
    this.customer_account = session.wallet;

    // this.orderInfo.seller_id === this.customer_id => 버튼 제거

    console.log("this.orderInfo", this.orderInfo);

    // 현재 오더가 세션과 동일한지 확인
    if (
      this.orderInfo.seller_id &&
      this.orderInfo.seller_id === this.customer_id
    ) {
      this.is_mine = true;
    }

    // 현재 상품이 판매되었는지 확인
    if (this.orderInfo.remain === 0) {
      this.is_sold = true;
    }

    // 일반 판매일 때
    if (this.orderInfo.order_type === 1 && this.orderInfo.id) {
      const price_fixed = removeUnusedZero(
        this.orderInfo.price_fixed,
        this.orderInfo.decimals
      );

      this.price_fixed = price_fixed;
      this.visual_price_fixed = setVisualPrice(price_fixed, this.digits);
    }

    if (this.orderInfo.order_type === 2) {
      const start_price = removeUnusedZero(
        this.orderInfo.price_begin,
        this.orderInfo.decimals
      );

      this.start_price = start_price;
      this.visual_start_price = setVisualPrice(start_price, this.digits);
    }

    if (this.orderInfo.order_type === 2 && this.bid_list[0]) {
      console.log("bid list @@@@@ ::::", this.bid_list);
      const highest_price = removeUnusedZero(
        this.bid_list[0].price,
        this.orderInfo.decimals
      );

      this.highest_price = highest_price;

      this.visual_highest_price = setVisualPrice(highest_price, this.digits);
    }

    if (this.orderInfo.order_type === 2 && this.orderInfo.immediate_buyable) {
      const buy_now_price = removeUnusedZero(
        this.orderInfo.immediate_price,
        this.orderInfo.decimals
      );

      this.buy_now_price = buy_now_price;

      this.visual_buy_now_price = setVisualPrice(buy_now_price, this.digits);
    }

    if (this.orderInfo.end_at) {
      const total = Date.parse(this.orderInfo.end_at) - Date.parse(new Date());

      total < 0 ? (this.is_auction_end = true) : (this.is_auction_end = false);
    }

    this.createdEnd = true;
  },

  // props를 받으면 화면 변환이 일어나면서 updated가 된다. (2번 하는 것으로 확인됨. -> watch 대체문)
  // updated() {

  //   // 옥션 판매일 때
  //   // orderInfo.id가 있으면 orderInfo를 다 가지고 온 것으로 간주하고 실행. (리뷰 필요 - 서민혁)

  //   // bid list가 없으면 highest bid price = 0으로 셋팅.
  // },

  methods: {
    makeHashtag(tag) {
      return `http://${tag}`;
    },

    onSearchByTag(tag) {
      tag = tag.substring(1);

      this.$router.push(`/market?hashtag=${tag}`);
    },

    checkForSale() {
      return new Promise((resolve) => {
        const now = new Date();
        const beginAt = new Date(this.orderInfo.begin_at);

        if (now < beginAt) {
          resolve(false);
        } else resolve(true);
      });
    },

    loadExRate() {
      OpenapiService.pricing().then((res) => {
        this.exchangeRate = res.data;
      });
    },

    getBidUsers(market_id) {
      return new Promise((resolve, reject) => {
        OpenapiService.bidGetUserList(market_id)
          .then((res) => {
            resolve(res.data.items);
          })
          .catch((e) => {
            notify({ type: "error", text: e.message });
          });
      });
    },

    checkSession: function() {
      return new Promise((resolve, reject) => {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            let result;
            const metamask_wallet = res[0];

            result = SessionService.valid(metamask_wallet) ? true : false;

            if (!result) {
              notify({
                type: "warn",
                text: "Login is required",
              });
            }

            resolve(result);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    checkWallet: function() {
      return new Promise((resolve, reject) => {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            let result, metamask_wallet;
            if (res && res[0]) {
              metamask_wallet = res[0].toLowerCase();
            } else result = false;

            if (!this.customer.wallet) result = false;

            result =
              metamask_wallet === this.customer.wallet.toLowerCase()
                ? true
                : false;

            if (!result) {
              notify({
                type: "error",
                text: "Session's wallet and metamask's wallet are diffrent",
              });
            }

            resolve(result);
          })
          .catch((e) => {
            notify({ type: "error", text: e.message });
            reject(e);
          });
      });
    },

    checkNetwork: function() {
      return new Promise((resolve, reject) => {
        const network_id = Number(window.ethereum.chainId);

        if (network_id !== this.orderInfo.chain_id) {
          notify({
            type: "error",
            text: `The artwork's network is not ${NETWORK[network_id]}`,
          });
        }

        resolve(network_id === this.orderInfo.chain_id);
      });
    },

    // 구매 버튼 클릭 => login 지갑과 메타마스크 지갑 비교
    onBuyModal: async function() {
      // 아직 판매 시간이 되지 않았을 때
      if (!this.forSale) {
        notify({
          type: "error",
          text: "Preparing now",
        });

        return;
      }

      let session_check, wallet_check, network_check;

      // metamask 설치 여부 확인
      const metamask = await checkMetamaskInstallation();

      if (!metamask) {
        notify({
          type: "error",
          text: "Metamask is not installed",
        });

        return;
      }

      session_check = await this.checkSession();

      if (session_check) {
        wallet_check = await this.checkWallet();
        network_check = await this.checkNetwork();
      }

      if (session_check && wallet_check && network_check) {
        this.$refs.ModalBuyNow.showModal();
      }
    },

    onBidModal: async function() {
      // 아직 판매 시간이 되지 않았을 때
      if (!this.forSale) {
        notify({
          type: "error",
          text: "Preparing now",
        });

        return;
      }

      let session_check, wallet_check, network_check;

      // metamask 설치 여부 확인
      const metamask = await checkMetamaskInstallation();

      if (!metamask) {
        notify({
          type: "error",
          text: "Metamask is not installed",
        });

        return;
      }

      session_check = await this.checkSession();

      if (session_check) {
        wallet_check = await this.checkWallet();
        network_check = await this.checkNetwork();
      }

      if (session_check && wallet_check && network_check) {
        this.$refs.ModalPlaceBid.showModal();
      }
    },

    // 현재 안 쓰는 것
    openPhoto() {
      this.open_viewer = !this.open_viewer;
    },

    closePhoto() {
      this.open_viewer = !this.open_viewer;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";

.content__layout {
  width: 100%;
  height: 87.5vh;
}

.owners__bg {
  background-color: white;
  border-color: #dddddd;
  border-bottom-width: 1px;
}

.hover__bg--black:hover {
  background-color: #262626;
  color: white;
}

.border-t-26 {
  border-top: 0.5px solid #262626 !important;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
</style>
