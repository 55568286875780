<template>
  <div
    id="subPolicyInfo"
    class="
      policy__layout
      font-thin
      text-parula-dark
      overflow-y-scroll
      max-h-96
      mx-3
      pt-6
    "
  >
    <!-- <h1>POLICY</h1> -->

    <h1 class="text-sm mt-3 mx-2">
      Transactions Are Recorded on the Public Klaytn Blockchain
    </h1>
    <p class="text-xs my-3 mx-2 text-justify">
      Transactions that take place on the Platform are managed and confirmed via
      the Klaytn blockchain. The User understands that your Klaytn public
      address will be made publicly visible whenever you engage in a transaction
      on the Platform.  We neither own nor control MetaMask, Coinbase, Google
      Chrome, the Klaytn network, or any other third-party site, product, or
      service that you might access, visit, or use for the purpose of enabling
      you to use the various features of the Platform. We will not be liable for
      the acts or omissions of any such third parties, nor will we be liable for
      any damage that you may suffer as a result of your transactions or any
      other interaction with any such third parties. The Platform Is Property of
      Palura, Inc. You acknowledge and agree that we (or, as applicable, our
      licensors) own all legal rights, title,s and interests in and to all
      elements of the Platform.  The graphics, design, systems, methods,
      information, computer code, software, services, “look and feel”,
      organization, a compilation of the content, code, data, and all other
      elements of the Platform (collectively, the “PARULA Materials”) are owned
      by CILO, and are protected by copyright, trade dress, patent, and
      trademark laws, international conventions, other relevant intellectual
      property and proprietary rights, and applicable laws.  All CILO
      Materials are the copyrighted property of CILO or its licensors, and all
      trademarks, service marks, and trade names contained in the CILO
      Materials are proprietary to CILO or its licensors. Except as expressly
      set forth herein, your use of the Platform does not grant you ownership of
      or any other rights with respect to any content, code, data, or other
      materials that you may access on or through the Platform. We reserve all
      rights in and to the CILO Materials not expressly granted to you in the
      Terms.
    </p>
    <h1 class="text-sm mt-3 mx-2">
      CILO May Use and Share All User Feedback
    </h1>
    <p class="text-xs my-3 mx-2 text-justify">
      You may choose to submit comments, bug reports, ideas, or other feedback
      about the Platform, including without limitation about how to improve the
      Platform (collectively, “Feedback”). By submitting any Feedback, you agree
      that we are free to use such Feedback at our discretion and without
      additional compensation to you, and to disclose such Feedback to third
      parties (whether on a non-confidential basis or otherwise). You hereby
      grant us a perpetual, irrevocable, non-exclusive, worldwide license under
      all rights necessary for us to incorporate and use your Feedback for any
      purpose.
    </p>
    <h1 class="text-sm mt-3 mx-2">
      CILO is a Non-Custodial Service Provider
    </h1>
    <p class="text-xs my-3 mx-2 text-justify">
      The Smart Contracts and the Site facilitate User collection of CILO
      Items, but CILO and its affiliates, the Platform, and the Smart
      Contracts are not the custodians of any User-owned CILO Items.  The User
      understands and acknowledges that the Smart Contracts do not give CILO
      custody, possession, or control of any CILO Item or cryptocurrency at
      any time for the purpose of facilitating CILO Item transactions.  You
      affirm that you are aware and acknowledge that CILO is a non-custodial
      service provider and has designed this Platform to be directly accessible
      by the Users without any involvement or actions taken by CILO or any
      third party.
    </p>
    <h1 class="text-sm mt-3 mx-2">External Sites</h1>
    <p class="text-xs my-3 mx-2 text-justify">
      The Platform or Site may include hyperlinks to other websites or resources
      (collectively, “External Sites”), which are provided solely as a
      convenience to our users.  We have no control over any External Sites.  
      You acknowledge and agree that we are not responsible for the availability
      of any External Sites and that we do not endorse any advertising,
      products, or other materials on or made available from any External Sites.
      Furthermore, you acknowledge and agree that we are not liable for any loss
      or damage which may be incurred as a result of the availability or
      unavailability of the External Sites, or as a result of any reliance
      placed by you upon the completeness, accuracy, or existence of any
      advertising, products or other materials on, or made available from, any
      External Sites.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.policy__layout {
  max-height: 25rem;
}

#subPolicyInfo {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#subPolicyInfo::-webkit-scrollbar {
  display: none;
}
</style>
