<template>
  <div
    class="flex justify-center items-center w-full overflow-auto hide-scrollbar"
  >
    <div v-if="fileType === 'MP4'">
      <video
        :src="filePath"
        class="h-screen"
        autoplay
        loop
        controls
        playsinline
        controlslist="nodownload"
      />
    </div>
    <div v-else>
      <img :src="filePath" class="h-screen" />
    </div>
  </div>
</template>

<script >
export default {
  // 추후 고민 해야 할 내용
  // 1. 컨텐츠(이미지, 동영상)의 넓이, 높이 둘중 하나라도 사이즈가 화면을 넘어 서면 비율을 유지해서 넘는 쪽을 화면사이즈에 마추어 줄여서 보여줘야 하고,
  // 2. 컨텐츠(이미지, 동영상)의 넓이, 높이 둘다 사이즈가 화면을 넘지 않으면 원본 이미지로 보여줘야 함.
  // 3. 아이디어는 content_media 테이블의 properties 필드에 media.track[1].whdth, heigth 정보가 있음
  props: {
    filePath: String,
    fileType: String,
  },
};
</script>

<style scoped>
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
</style>
