<template>
  <div
    v-if="historyInfo"
    id="subHistoryInfo"
    class="history__layout history-wrapper overflow-y-scroll overflow-hidden"
  >
    <div v-for="item in historyInfo.items" :key="item.id">
      <div v-if="item.event == 'mint'" class="w-full flex flex-row p-4">
        <!-- <img
          :src="item.target_thumbnail"
          class="w-12 h-12 rounded-full object-cover mr-5"
        /> -->
        <div class="w-12 h-12 rounded-full object-cover mr-5">
          <content-viewer :filePath="item.target_thumbnail" />
        </div>

        <div class="font-medium text-base parula__text-black">
          <div>
            <div v-if="item.transaction_hash">
              <a v-bind:href="makeExplorerLink(item)" target="_blank">Minted</a>
            </div>
            <div v-else>Minted</div>
            <span>{{ item.asset_amount }}</span> edition for
            <span>{{ item.asset_symbol }}</span>
          </div>
          <p>
            by
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>

      <div
        v-else-if="item.event == 'listed'"
        class="w-full flex flex-row border-b p-4 overflow-x-auto"
      >
        <img
          v-if="item.user_avatar != ''"
          :src="item.user_avatar"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <img
          v-else
          :src="pic"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <div class="font-medium text-base parula__text-black">
          <div>
            Listed for
            <span>{{ item.asset_symbol }}</span>
          </div>
          <p>
            by
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>

      <div
        v-else-if="item.event == 'bid'"
        class="w-full flex flex-row border-b p-4"
      >
        <img
          v-if="item.user_avatar != ''"
          :src="item.user_avatar"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <img
          v-else
          :src="pic"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <div class="font-medium text-base parula__text-black">
          <div>Bid</div>
          <p>
            by
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>

      <div
        v-else-if="item.event == 'sold'"
        class="w-full flex flex-row border-b p-4"
      >
        <img
          v-if="item.user_avatar != ''"
          :src="item.user_avatar"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <img
          v-else
          :src="pic"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <div class="font-medium text-base parula__text-black">
          <div>
            <div v-if="item.transaction_hash">
              <a v-bind:href="makeExplorerLink(item)" target="_blank">Sold</a>
            </div>
            <div v-else>Sold</div>
            <span>{{ item.asset_amount }}</span> edition
          </div>
          <p>
            to
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>

      <div
        v-else-if="item.event == 'wontheauction'"
        class="w-full flex flex-row border-b p-4"
      >
        <img
          v-if="item.user_avatar != ''"
          :src="item.user_avatar"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <img
          v-else
          :src="pic"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <div class="font-medium text-base parula__text-black">
          <div v-if="item.transaction_hash">
            <a v-bind:href="makeExplorerLink(item)" target="_blank"
              >Won the auction</a
            >
          </div>
          <div v-else>Won the auction</div>
          <p>
            by
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>

      <div v-else class="flex items-center w-full border-b p-4">
        <img
          v-if="item.user_avatar != ''"
          :src="item.user_avatar"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />
        <img
          v-else
          :src="pic"
          class="w-12 h-12 rounded-full object-cover mr-5"
        />

        <div class="font-medium text-base parula__text-black">
          <div v-if="item.transaction_hash">
            <a v-bind:href="makeExplorerLink(item)" target="_blank">{{
              item.event
            }}</a>
          </div>
          <div v-else>{{ item.event }}</div>
          <p>
            by
            <span
              ><b>@{{ item.user_nickname }}</b></span
            >
            at
            <span class="text-xs">
              <!-- {{ item.created_at.replace("T", " ").replace(".000Z", "") }} -->
              {{ String(new Date(item.created_at)).substring(0, 15) }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pic from "@/assets/img/basic_image.png";
import ContentViewer from "@/components/ContentViewer.vue";

export default {
  props: {
    historyInfo: Object,
  },

  data: function() {
    return {
      pic: pic,
    };
  },

  components: {
    ContentViewer,
  },

  methods: {
    makeExplorerLink(item) {
      if (!item.transaction_hash) return;

      const chain_id_to_prefix = {
        1: "https://etherscan.io/tx",
        3: "https://ropsten.etherscan.io/tx",
        4: "https://rinkeby.etherscan.io/tx",
      };
      const prefix = chain_id_to_prefix[item.chain_id];
      if (!prefix) return;

      return `${prefix}/${item.transaction_hash}`;
    },
  },
};
</script>

<style scoped>
.history__layout {
  max-height: 25rem;
}

.border-b {
  border-bottom: 0.5px solid #262626;
}

#subHistoryInfo {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#subHistoryInfo::-webkit-scrollbar {
  display: none;
}
</style>
