<template>
  <div class="flex flex-row items-center text-xs text-parula-gray-1 space-x-2">
    <div>
      <!-- <span :data-text="start" class="tooltip left text-xs font-light">
        start
      </span> -->
      <VueCustomTooltip :label="start" position="is-bottom"
        >START</VueCustomTooltip
      >
    </div>
    <div
      class="parula__bg-gray-soft h-2 w-28"
      role="progressbar"
      :aria-valuenow="percent"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="bg-black h-2 text-center"
        :style="`width: ${percent}%; transition: width 3s;`"
      ></div>
    </div>
    <div>
      <!-- <span :data-text="end" class="tooltip left text-xs font-light">
        end
      </span> -->
      <VueCustomTooltip :label="end" position="is-bottom">END</VueCustomTooltip>
    </div>
  </div>
</template>


<script>
import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";

export default {
  props: {
    orderInfo: Object,
    begin_at: String,
    end_at: String,
  },

  components: {
    VueCustomTooltip,
  },

  data: function () {
    return {
      percent: 0,
      start: "0",
      end: "0",
    };
  },

  mounted() {
    setTimeout(() => {
      this.percent = this.remainPercent(this.begin_at, this.end_at);
    }, 1500);
  },

  methods: {
    remainPercent(begintime, endtime) {
      this.start = String(new Date(begintime));
      this.end = String(new Date(endtime));

      const total = Date.parse(endtime) - Date.parse(begintime);
      let current = Date.parse(endtime) - Date.parse(new Date());
      if (current < 0) {
        current = 0;
      }
      return Math.floor(100 - (current / total) * 100, 0);
    },
  },
};
</script>

<style></style>
